<template>
<div class="qrcode-info">
    <van-swipe :autoplay="5000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
            <img v-lazy="image.url" style="height: 200px; width: 100%" />
        </van-swipe-item>
    </van-swipe>

    <div class="form-box">
        <div class="title">{{ qrcode.form_title || '追溯信息查询' }}</div>
        <div class="subtitle" v-if="qrcode.form_subtitle">{{ qrcode.form_subtitle }}</div>

        <van-form>
            <van-field v-for="(item,index) in fields" :key="index"
              v-model="formData[item.key]" :label="item.label" :placeholder="'请填写' + item.label" />
            <!-- <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" /> -->
            <div style="margin: 16px;">
                <van-button :loading="loading" loading-text="加载中..." round block type="info" @click="search">
                  {{ qrcode.form_action || '查询' }}
                </van-button>
            </div>
        </van-form>
    </div>

</div>
</template>

<script>
import Vue from "vue";
import {
    Swipe,
    SwipeItem,
    Lazyload,
    Form,
    Field,
    Button
} from "vant";

import {
    List,
    Cell,
    Dialog,
    Toast,
    ImagePreview
} from "vant";

import "vant/es/dialog/style";

Vue.use(List);
Vue.use(Cell);

Vue.use(Dialog);
Vue.use(ImagePreview);

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);

Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);

export default {
    name: "qrcode",
    data() {
        return {
            qrcode: {},
            images: [],
            list: [],
            fields: [],
            showDialog: false,
            zj_content: null,
            formData: {},
            loading: false 
        };
    },
    created() {
        document.title = "溯源信息";
    },
    mounted() {
        // console.log(this.$route.params.sku);
        // const sku =
        document.title = "溯源信息";
        // console.log(222);

        this.loadData();
    },
    methods: {
      search() {
        // console.log(this.formData);
        if (JSON.stringify(this.formData) == '{}') {
          Toast('至少需要输入1个查询条件');
          return;
        }
        this.loading = true;
        this.$get('qrcode/qp/search', { id: this.qrcode.id, conds: JSON.stringify(this.formData) }, res => {
          console.log(res);
          this.loading = false;
          if (res.code == 0) {
            this.$router.push({ path: `/prod/${res.data.sku}`});
          } else {
            Toast(res.message);
          }
        });
      },
        imageChanged(ev) {
            // console.log(ev);
            if (ev.target.nodeName == "IMG") {
                console.log(12333);
                ImagePreview({
                    images: [ev.target.currentSrc],
                    showIndex: false,
                    loop: false,
                    closeable: true
                });
            } else {
                return;
            }
        },
        selectItem(item) {
            if (!item.is_link) return;

            this.showDialog = true;

            this.zj_content = this.qrcode.qrcode_info.zj_content;
            // Dialog.alert({
            //   title: "质检信息",
            //   allowHtml: true,
            //   messageAlign: "left",
            //   className: "cu-dialog",
            //   message: this.qrcode.qrcode_info.zj_content,
            // }).then(() => {
            //   // on close
            //   console.log(123);
            // });
        },
        loadData() {
            const sku = this.$route.params.id;
            this.$get("qrcode/qp/" + sku, null, (res) => {
                // console.log(res);
                if (res.code === 0) {
                    this.qrcode = res.data || {};
                    // if (this.qrcode.qrcode_info) {
                    this.images = this.qrcode.prod_image_assets || [];
                    // }

                    const arr = this.qrcode.query_page_cfgs || [];

                    const temp = [];
                    let idx = 0;
                    arr.forEach(ele => {
                      temp.push({ key: 'q' + idx, label: ele.n })
                      idx++;
                    });

                    this.fields = temp;

                    console.log(this.fields);

                    // this.list = temp;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
<style>
.cu-dialog {
    padding: 15px;
    font-size: 14px;
    color: #333;
    line-height: 1.2em;
    box-sizing: border-box;
}

.van-dialog__content {
    box-sizing: border-box;
    max-height: calc(100% - 100px);
    overflow-y: auto;
}

.cu-dialog img {
    max-width: 100% !important;
}
.title {
  font-size: 16px;
  color: #333;
  text-align: center;
  font-weight: bold;
  margin: 15px;

}
.subtitle {
  text-align: center;
  margin-bottom: 15px;
}
</style>
